export interface IImage {
  original: string;
  thumbnail: string;
}

export const images: IImage[] = [
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399843/PHOTO-2022-12-25-20-48-29_qsppos.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399843/PHOTO-2022-12-25-20-48-29_qsppos.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-28_laemjm.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-28_laemjm.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-47-26_g2jm81.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-47-26_g2jm81.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-37_qoanp8.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-37_qoanp8.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-46-41_gh3z1s.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-46-41_gh3z1s.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-32_efczvw.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399842/PHOTO-2022-12-25-20-47-32_efczvw.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-46-41_kosmqn.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672399841/PHOTO-2022-12-25-20-46-41_kosmqn.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-32_unrtob.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-32_unrtob.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-49-08_xmfase.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-49-08_xmfase.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-32_deofqv.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-32_deofqv.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-33_qlvfgl.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481683/lppm/PHOTO-2022-12-25-20-48-33_qlvfgl.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481682/lppm/PHOTO-2022-12-25-20-48-31_ekjqiz.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481682/lppm/PHOTO-2022-12-25-20-48-31_ekjqiz.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481682/lppm/PHOTO-2022-12-25-20-48-28_ijjpsz.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481682/lppm/PHOTO-2022-12-25-20-48-28_ijjpsz.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-30_ddfb65.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-30_ddfb65.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-26_kp50oy.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-26_kp50oy.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-27_i3ba2s.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-27_i3ba2s.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-29_uraz97.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-29_uraz97.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-24_ygyrxt.jpg",
    thumbnail:
      "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481681/lppm/PHOTO-2022-12-25-20-48-24_ygyrxt.jpg",
  },
  // {
  // original: "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
  // thumbnail: "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
  // },
  // {
  // original: "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
  // thumbnail: "https://res.cloudinary.com/dpi44zxlw/image/upload/v1672481684/lppm/PHOTO-2022-12-25-20-49-10_kqi7a3.jpg",
  // },
];
