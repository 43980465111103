export const theme = {
  token: {
    colorPrimary: "#00b96b",
    components: {
      Radio: {
        colorPrimary: "#00b96b",
      },
    },
  },
};
