export interface IVid {
  video: string;
}

export const videos: IVid[] = [
  {
    video:
      "https://res.cloudinary.com/dpi44zxlw/video/upload/v1672357789/320126638_826517348446191_1195080636727819656_n_agja4f.mp4",
  },
  {
    video:
      "https://res.cloudinary.com/dpi44zxlw/video/upload/v1672356747/322307310_176012935040347_7918079026087355917_n_tgiw9u.mp4",
  },
  {
    video:
      "https://res.cloudinary.com/dpi44zxlw/video/upload/v1672398679/10000000_507927247866055_8949269750556364856_n_awaezh.mp4",
  },
];
